
// Animation Css

//Mensaje Flotante
.my-fixed-item {
  position: fixed;
  min-height: 120px;
  width: 252px;
  z-index: 6;
  text-align: center;
  background-color: aquamarine;
}