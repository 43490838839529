 /*=====================
     Startups
  =======================*/


.startups-area{
  background-image: url(/assets/images/fondos-body/imagen07connect.jpg);
  position: relative;
  padding-top: 389px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-top: -166px;
  padding-bottom: 223px;
  margin-top: -166px;
  padding-top: 319px;
  position: relative;
  z-index: 2;


  .contenedorBtn{
    text-align: center;
  }


  @media #{$lg-layout} {
    margin-top: -347px;
    padding-bottom: 308px;
    padding-top: 436px;
  }
  @media #{$md-layout} {
    transform: rotate(0deg);
    //background-image: inherit;
    margin-top: 0;
    padding-bottom: 100px;
    padding-top: 100px;
  }
  @media #{$sm-layout} {
    transform: rotate(0deg);
    //background-image: inherit;
    margin-top: 0;
    padding-bottom: 0px;
    padding-top: 100px;
  }

  .bg-overlay{
    background: rgba(42, 42, 42, 0.48) none repeat scroll 0 0;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }


  &::before{
    background-image: url(/assets/images/app/shape-2.png);
    background-repeat: no-repeat;
    background-position: left top;
    background-size: cover;
    content: "";
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
  }
  &::after{
    background-image: url(/assets/images/app/shape-3.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    content: "";
    right: 0;
    position: absolute;
    bottom: -1px;
    width: 100%;
    z-index: 1;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  &::before,
  &::after {
    height: 460px;

    @media #{$md-layout} {
      height: auto;
    }

    @media #{$sm-layout} {
      height: auto;
    }
  }

  &.horizontal {
    margin-top: 0;
    padding-bottom: 100px;
    padding-top: 100px;
    position: relative;
    z-index: 2;
    background-image: url(/assets/images/bg/bg-image-1.jpg);
    margin-bottom: 77px;
    &::before ,
    &::after {
      height: auto;
    }
    &::before{
      background-image: inherit;
    }
    &::after{
      transform: rotate(0deg);
      background-image: inherit;
    }

    @media #{$sm-layout} {
      margin-bottom: 0;
    }
  }
}



