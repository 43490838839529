.colorDesafio{
  background-color: #f0f0f0;
  padding: 24px;
}



.contendorDesafioDestacado{
  padding: 13px;
  background: rgb(255, 255, 255);
  text-align: initial;
  border-radius: 13px;
  margin-bottom: 14px;
  margin-top: 25px;
  /*margin-left: 20px;*/
  /*margin-right: 20px;*/
}

.contenedorLogoEmpresa{
  margin-left: 5px !important;
  max-width: 1500px;
  margin-top: 30px;
}
.titulo1{
  font-weight: 900;
  line-height: 16px;
  margin-bottom: 28px;
  margin-top: 28px;
  /*margin: 15px 5px 12px;*/
}
#titulo2{
  /*color: rgb(0, 149, 58);*/
  font-weight: 700;
  line-height: 19px;
  margin-bottom: 28px;
  /*margin: 15px 5px 12px;*/
}
.styleBtnPostular{
  color: #ffff;
  border-radius: 15px;
  margin-right: 5px;
  font-weight: 900;
  background: rgb(255, 87, 34);
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}