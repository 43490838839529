 /*=====================
     Planes
  =======================*/


.planes{
  height: 180vh;
  margin-bottom: 10px;
  
  .planes-area{
    margin-bottom: 10px; 
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    margin-left: auto;
    margin-right: auto;
    width: 70vw;
    background-image: url(/assets/images/planes/planes-innovaconnect.png);
  }

  @media only screen and (max-width: 2560px){
    .planes-area{
      height: 1000px;
      width: 50%;
      background-size: contain;
    }
  }

  @media only screen and (min-width: 1901px) and (max-width: 2559px){
    .planes-area{
      height: 100vh;
      width: 80vw;
      background-size: contain;
    }
  }
  
  @media only screen and (min-width: 1801px) and (max-width: 1900px){
    .planes-area{
      height: 110vh;
      width: 80vw;
      background-size: contain;
    }
  }
  
  @media only screen and (min-width: 1601px) and (max-width: 1800px) {
    .planes-area{
      height: 200vh;
      width: 70vw;
      background-size: contain;
    }
  }

  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    .planes-area{
      height: 700px;
      width: 60vw;
      background-size: contain;
    }
  }
  
  @media only screen and (min-width: 1301px) and (max-width: 1400px) {
    .planes-area{
      height: 180vh;
      width: 70vw;
      background-size: contain;
    }
  }
  
  @media only screen and (min-width: 1201px) and (max-width: 1300px) {
    .planes-area{
      height: 140vh;
      width: 65vw;
      background-size: contain;
    }
  }
  
  @media only screen and (max-width: 1024px) {
    .planes-area{
      height: 550px;
      width: 70%;
      background-size: contain;
    }
  }
  
  @media only screen and (max-width: 992px) {
    .planes-area{
      height: 650px;
      width: 80vw;
      background-size: contain;
    }
  }
  
  @media only screen and (max-width: 768px) {
    .planes-area{
      height: 500px;
      width: 90%;
      background-size: contain;
    }
  }
  
  @media only screen and (max-width: 425px) {
    .planes-area{
      height: 320px;
      width: 100%;
      background-size: contain;
    }
  }
  
  @media only screen and (max-width: 375px) {
    .planes-area{
      height: 280px;
      width: 100%;
      background-size: contain;
    }
  }
  
  @media only screen and (max-width: 320px) {
    .planes-area{
      height: 250px;
      width: 100%;
      background-size: contain;
    }
  }
}


@media only screen and (max-width: 2560px){
  .planes{
    height: 1250px;
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 1901px) and (max-width: 2559px){
  .planes{
    height: 130vh;
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 1801px) and (max-width: 1900px){
  .planes{
    height: 250vh;
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 1601px) and (max-width: 1800px) {
  .planes{
    height: 210vh;
    margin-bottom: 200px;
  }
}

@media only screen and (min-width: 1401px) and (max-width: 1600px) {
  .planes{
    height: 900px;
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 1301px) and (max-width: 1400px) {
  .planes{
    height: 225vh;
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1300px) {
  .planes{
    height: 185vh;
    margin-bottom: 35px;
  }
}

@media only screen and (max-width: 1024px) {
  .planes{
    height: 1000px;
    margin-bottom: 0px;
  }
}

@media only screen and (max-width: 992px) {
  .planes{
    height: 1050px;
  }
}

@media only screen and (max-width: 768px) {
  .planes{
    height: 570px;
    margin-bottom: 0px;
  }
}

@media only screen and (max-width: 425px) {
  .planes{
    height: 380px;
    margin-bottom: 0px;
  }
}

@media only screen and (max-width: 375px) {
  .planes{
    height: 340px;
    margin-bottom: 0px;
  }
}

@media only screen and (max-width: 320px) {
  .planes{
    height: 320px;
    margin-bottom: 0px;
  }
}



