.rcw-conversation-container .rcw-header {
    background-color: #fd601c !important;
  }

.rcw-conversation-container .rcw-title {
    color: white;
}
  
.rcw-conversation-container .avatar {
    position: absolute;
    width: 90px !important;
    height: 60px !important;
    margin-left: 30px !important;
    top: 18px;
}

.rcw-launcher {
    background-color: #fd601c !important;
}

.rcw-picker-btn {
    display: none;
}

.rcw-new-message {
    width: calc(100% - 45px) !important;
}